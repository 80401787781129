import React from 'react';
import { Breakpoints } from '@settings';
import { TResponsiveImage } from '@types';
import { useMediaQuery } from 'react-responsive';

type TProps = {
  image: TResponsiveImage;
  breakpoint?: number;
} & Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src' | 'alt'>;

const ResponsiveImage = ({ image, breakpoint = Breakpoints.MD, ...rest }: TProps) => {
  const shouldShowMobile = useMediaQuery({ maxWidth: breakpoint });
  const { desktop, mobile, alt } = image;

  return <img src={shouldShowMobile ? mobile : desktop} alt={alt} {...rest} />;
};

export default ResponsiveImage;
